module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dalipjaggi","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/assets/images/d-favicon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-152591322-1"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
